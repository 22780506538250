.event-tile-container {
  min-width: 253px;
  max-width: 253px;
  height: 370px;
  border-radius: 8px;
  box-shadow: inset 0px -190px 28px -19px #000000;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-size: contain;
  margin-right: 16px;

  &:hover {
    cursor: pointer;
  }

  > .event-tile-content {
    height: 160px;
    width: auto;

    > .event-tile-title {
      font-size: 20px;
      font-weight: 600;
      margin: 8px;
      height: 56px;
      width: auto;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    > .event-tile-locate {
      height: 18px;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      font-size: 14px;
      margin-bottom: 8px;
      margin-right: 8px;
      margin-left: 8px;

      > span {
        margin-right: 4px;
      }
    }

    > .event-tile-tag {
      display: flex;
      align-items: center;
      font-size: 12px;
      width: fit-content;
      height: 24px;
      margin-bottom: 8px;
      padding-left: 6px;
      padding-right: 6px;
      border-radius: 2px;
      margin-left: auto;
      margin-right: auto;
      background-color: rgba(247, 227, 198, 0.3);
      color: #FCC470;
      font-weight: 600;
    }
  }
}

.--citc-event-tile {
  width: 163px;
  margin-top: 16px;
  border-radius: 8px;
  background-color: rgb(0 0 0 / 4%);
  box-shadow: 2px 2px 2px rgb(0 0 0 / 11%);

  > .image-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 217px;
    background-size: cover;
    background-position: 50%;
    border-radius: 8px;
    position: relative;

    > .canvas-background {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      border-radius: 8px;
    }

    > img {
      z-index: 2;
      border-radius: 8px;
      height: fit-content;
      max-height: 217px;
    }

    > .rating {
      z-index: 3;
      position: absolute;
      left: 0;
      bottom: 3%;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      background-color: rgba(4, 83, 241, 0.9);
      padding: 4px 8px 4px 2px;
      border-radius: 0 5px 5px 0;
      color: #ffffff;

      > .icon {
        font-size: 16px;
        font-family: culturinthecity;
        color: #ffffff;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  > .category {
    display: none;
    height: 24px;
    margin-top: 8px;
    padding: 0 5px;

    &:hover {
      cursor: pointer;
    }
  }

  > .dates {
    display: none;
  }

  > .tooltip {
    width: 100%;
    padding: 0 10px;

    > h2 {
      font-size: 18px;
      font-family: 'EB Garamond';
      font-weight: 600;
      margin-top: 8px;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
      }
    }
  }

  > p {
    width: auto;
    display: flex;
    align-items: start;
    font-size: 14px;
    margin-bottom: 8px;
    padding: 0 10px;

    > span {
      padding-top: 2px;
      margin-right: 4px;
      font-size: 16px;
      color: #8A9099;
    }
  }

  > .locates {
    display: block;
    padding: 0 10px;

    > p {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      font-size: 16px;
      margin-bottom: 8px;

      &:first-child {
        &:hover {
          cursor: pointer;
        }
      }

      > span {
        margin-right: 4px;
        font-size: 16px;
        color: #8A9099;
      }
    }
  }
}

@media (min-width: 975px) {
  .--citc-event-tile {
    width: 220px;

    > .image-rating {
      height: 293px;

      > img {
        max-height: 293px;
      }

      > .rating {
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 2px;

        > span {
          font-size: 18px;
          font-family: culturinthecity;
          color: #0454F0;
        }
      }
    }

    > .category {
      display: block;
    }

    > .dates {
      display: block;
    }

    > div {
      display: flex;
      justify-content: space-between;
    }

    > .tooltip {
      > h2 {
        font-size: 22px;
        margin-top: 8px;
        margin-bottom: 6px;
      }
    }
  }
}

@media (min-width: 1080px) {
  .event-tile-container {
    min-width: 293px;
    max-width: 293px;
    height: 475px;
    box-shadow: inset 0px -220px 28px -19px #000000;
    background-size: contain;
    margin-bottom: 16px;

    > .event-tile-content {
      height: 190px;

      > .event-tile-title {
        height: 65px;
        font-size: 24px;
        margin-bottom: 16px;
      }

      > .event-tile-locate {
        font-size: 16px;
        margin-bottom: 16px;
      }
    }
  }
}
