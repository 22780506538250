.placeholder-checklist {
  margin-left: 16px;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.placeholder-checklist::after {
  content: ' \ed7a';
  font-family: culturinthecity;
  font-size: 15px;
}

.filled-checklist {
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.filled-checklist-default {
  color: black;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  margin-top: 5px;
}

.placeholder-checklist-default {
  color: #8A9099;
  margin-top: 5px;
  margin-left: 10px;
}

.checkboxes-list-title {
  font-weight: 600;
}

.checkboxes-input-hint {
  font-size: 12px;
  margin-bottom: 10px;
}
