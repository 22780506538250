.--citc-brand-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 68px;

  > img {
    border-radius: 4px;
  }

  > .section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    padding: 0 10px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 20px;

      > h1 {
        font-family: 'EB Garamond';
        font-weight: 700;
        font-size: clamp(18px, 4vw, 48px);
      }

      > a {
        font-family: 'Inter';
        font-style: italic;
        max-width: 680px;
        font-weight: 200;
        font-size: clamp(14px, 3.2vw, 36px);
        text-decoration-line: underline;
        color: #3549FF;

        &:hover {
          cursor: pointer;
        }
      }
    }

    > img {
      width: 33%;
      max-width: 400px;
      height: auto;
      object-fit: cover;
      box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
      border-radius: 11px;
    }
  }
}

@media (min-width: 1440px) {
  .--citc-brand-image {
    > .section {
      justify-content: space-between;
      padding: 0 80px;
    }
  }
}
