.--citc-toggable-section {
  border-top: 2px solid #8A9099;
  margin-top: 24px;
  padding-top: 16px;

  > .toggle-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    > h2 {
      font-size: 20px;
      font-weight: 600;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.closed {
    > .toggle-title {
      > span {
        transform: rotate(180deg);
      }
    }

    > .content {
      display: none;
    }
  }

  > .--citc-custom-input {
    max-width: none;
  }
}
