.select-bdate {
    display: flex;
}

.select-bday,
.select-bmonth,
.select-byear {
    text-align: center;
    border: none;
    background-color: transparent;
    font-size: 14px;
    //supprimer ces lignes quand tailwind ne sera plus sur le site
    padding: 0 !important;
    background-size: 0 !important;
    background-image: none !important;
    margin-left: 10px;
    ///////////////////
}

.separator-select-bdate {
    font-size: 25px;
    color: lightgray;
}

.single-date-input {
    height: auto;
    background-color: white;
    border: solid 1px lightgrey;
    border-radius: 5px;
}

.hidden-true-value {
    visibility: hidden;
}
