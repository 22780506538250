#shows-index {
  background-color: white;
  overflow-x: hidden;
}

.--citc-shows {
  display: flex;
  background-color: white;
  position: relative;

  > .left {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    background-color: #F2F4F7;
    transform: translateY(0%);
    transition: transform .5s linear;
    margin: -0.5rem;

    &.inactive {
      transform: translateY(-100%);
    }
  }

  > .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    > .header {
      display: flex;
      justify-content: space-between;
      align-items: end;

      > h1 {
        font-family: 'EB Garamond';
        font-size: 32px;
        font-weight: 500;
        line-height: 120%;
      }
    }

    > p {
      font-size: 14px;
    }

    > a {
      margin-left: auto;
      margin-right: auto;
      margin-top: 28px;
    }
  }
}

@media (min-width: 975px) {
  .--citc-shows {
    > .left {
      position: inherit;
      z-index: 10;
      width: inherit;
      transition: transform 0s linear;

      &.inactive {
        transform: translateY(0%);
      }
    }

    > .right {
      width: 70%;
      margin-left: 16px;

      > .header {
        > h1 {
          font-size: 56px;
        }

        > .--citc-custom-button {
          display: none;
        }
      }

      > .slider {
        > ul {
          > li {
            min-width: 190px;
            font-size: 20px;
          }
        }
      }
    }
  }

  #shows-index {
    &.unscrollable {
      overflow: auto;
    }
  }
}

@media (min-width: 1080px) {
  .--citc-shows {
    > .right {
      width: 75%;
    }
  }
}
