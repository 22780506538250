.--citc-header-tile {
  display: none;

  >.content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: fit-content;
    height: 330px;

    >img {
      width: 200px;
      height: 165px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5px;
      -o-object-fit: contain;
      object-fit: cover;

      &.product {
        width: 164px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        border-radius: 8px;
        object-fit: contain;
      }

      &:hover {
        cursor: pointer;
      }
    }

    >p {
      width: 190px;
      margin: 0px 10px;
      white-space: normal;
      font-weight: 600;
      font-size: 16px;

      display: flex;
      align-items: start;

      color: #202222;

      &:hover {
        cursor: pointer;
      }
    }

    >.price {
      width: auto;
      height: auto;

      gap: 10px;

      font-weight: 400;
      font-size: 14px;

      display: flex;
      flex-direction: column-reverse;
      color: #3A3E44;

      &:hover {
        cursor: pointer;
      }

      >p {
        width: fit-content;
        color: #0454F0;
        font-weight: 600;
      }
    }
  }
}

@media (min-width: 1200px) {
  .--citc-header-tile {
    display: flex;
  }
}
