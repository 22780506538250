.mobile-search-bar-container {
    display: flex;
    flex-direction: column;
    background-color: #F2F4F6;
    height: 68.5vh;
}

.mobile-search-results-list{
    margin-bottom: 16px;
}

.mobile-search-bar-global-section {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 20px;
    background-color: #F2F4F6;
    height: auto;
    overflow-y: scroll;
}

.mobile-search-title {
    font-weight: 600;
}

.separator-mobile-search {
    width: auto;
    border-bottom: solid 2px #8A9099;
}

.mobile-search-head-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    p {
        font-family: "EB Garamond", serif;
        font-size: 32px;
        font-weight: 500;
    }
}

.mobile-search-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding-bottom: 16px;

    p {
        font-size: 20px;
        font-weight: 500;
    }
}

.toggle-section-icon {
    rotate: -90deg;
    font-size: 14px !important;
    color: #0454F0;
}

.search-input-default {
    margin-bottom: 24px;
    background-color: white;
    border: solid 1px #D2D5DA;
    width: auto;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 4px;

    input {
        margin-left: 10px;
        max-width: 170px;
    }

    span {
        font-size: 16px !important;
        color: #8A9099;
        margin-left: 16px;
    }

    input:focus{
        outline: none;
    }
}

.mobile-search-checklist {
    margin-bottom: 16px;

    div {
        margin-bottom: 14px;

        input {
            margin-right: 10px;
        }
    }
}

.mobile-search-hidden {
    .mobile-search-toggle-element {
        display: none;
        transition: height 0.5s linear;
    }

    .toggle-section-icon {
        rotate: 90deg;
    }
}

.mobile-search-toggle {
    .mobile-search-toggle-element{
        display: block;
        transition: height 0.5s linear;
    }
}

.mobile-search-cta {
    width: auto;
    background-color: white;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
