.--citc-custom-input-date {
  > label {
    font-weight: 500;
    color: black;
    width: auto;
  }

  > .default {
    margin-bottom: 24px;
    padding: 0 16px;
    background-color: white;
    border: solid 1px #D2D5DA;
    width: auto;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  > .always-show {
    width: 100% !important;
    margin: 2px;

    > .date-selector {
      background-color: white;
      border-radius: 8px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);

      > .selected-dates {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 45px;
        width: 97%;
        background-color: white;
        padding: 5px;
        margin-left: 5px;
        border-top: 2px solid #00000030;

        > span {
          font-size: 15px;

          &:hover {
            color: red;
            cursor: pointer;
          }
        }

        > p {
          > .selected-date {
            color: #0454F0;
            font-weight: 500;
          }
        }
      }
    }

    > .preselect {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: 5px;
    }

    .DateRangePicker {
      display: block;
      width: 100% !important;

      > div {
        width: 100% !important;

        > .DateRangePickerInput {
          padding: 0;
          width: 100% !important;

          > .DateInput {
            display: none;
          }

          > .DateRangePickerInput_calendarIcon {
            display: none;
          }

          > .DateRangePicker_picker {
            width: 96% !important;
            margin-left: 5px;
            position: relative;

            > .DayPicker {
              left: 1px;
              right: 0;
              width: 100% !important;
              box-shadow: none !important;
              border-radius: 0%;

              > div {
                width: 100% !important;

                > div {
                  width: 100% !important;

                  > .DayPicker_weekHeaders {
                    width: 100% !important;
                    margin: 0 !important;

                    > .DayPicker_weekHeader {
                      width: 100% !important;
                      left: 0;
                      padding: 0 !important;

                      > ul {
                        display: flex;
                        justify-content: space-between;

                        > li {
                          width: none !important;
                        }
                      }
                    }
                  }

                  > .DayPicker_focusRegion {
                    width: 100% !important;

                    > .DayPickerNavigation {
                      width: 100% !important;
                      margin-left: 0;
                    }

                    > .DayPicker_transitionContainer {
                      width: 100% !important;
                      height: 324px !important;

                      > .CalendarMonthGrid {
                        width: 100% !important;
                        left: 0;

                        > .CalendarMonthGrid_month__horizontal {
                          width: 100% !important;

                          .CalendarMonth {
                            width: 100% !important;
                            padding: 0 !important;

                            > .CalendarMonth_caption {
                              width: 45% !important;
                              height: 90px;
                              text-align: center;
                              margin: 0 auto;
                              padding-top: 10px;
                              padding-bottom: 40px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                            }

                            > table {
                              width: 100% !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .CalendarDay {
              border: none;

              &.CalendarDay__default {
                &.CalendarDay__selected {

                  &,
                  &:hover,
                  &:active {
                    color: white;
                    background-color: #0454F0;
                    border: none;
                  }

                  &.CalendarDay__selected_start {
                    border-radius: 150px 0 0 150px;

                    &:hover {
                      background-color: rgb(4, 84, 240, .7);
                    }
                  }

                  &.CalendarDay__selected_end {
                    border-radius: 0 150px 150px 0;

                    &:hover {
                      background-color: rgb(4, 84, 240, .7);
                    }
                  }
                }

                &.CalendarDay__selected_span {

                  &,
                  &:hover {
                    color: #484848;
                    border: none;
                  }

                  background-color: rgb(4, 83, 240, .1);

                  &:hover {
                    background-color: rgb(4, 83, 240, .5);
                  }
                }

                &.CalendarDay__hovered_span {

                  &,
                  &:hover {
                    border: none;
                  }

                  color: #484848;
                  background-color: rgb(4, 83, 240, .3);

                  &:hover {
                    color: white;
                    background-color: rgb(4, 84, 240, .7);
                    border-radius: 0 150px 150px 0;
                  }
                }
              }
            }
          }

          > .DateRangePickerInput_arrow {
            display: none;
          }
        }
      }
    }
  }

  > .same {
    .DateRangePicker {
      > div {
        > .DateRangePickerInput {
          > .DateRangePicker_picker {
            .CalendarDay {
              &.CalendarDay__default {
                &.CalendarDay__selected {
                  &.CalendarDay__selected_start {
                    border-radius: 150px;
                  }

                  &.CalendarDay__selected_end {
                    border-radius: 150px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
