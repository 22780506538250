.--citc-shows-results {
  > .results {
    display: grid;
    grid-template-columns: repeat(auto-fill, 163px);
    grid-gap: 8px;
    justify-content: space-evenly;
  }

  > .no-result {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 20% 5%;
    align-items: center;
    gap: 10px;

    > h3 {
      font-size: 21px;
      font-family: 'EB Garamond';
    }

    > p {
      font-size: 14px;
      color: #8A9099;
    }
  }

  > .low-result {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 5% 5%;
    align-items: center;
    gap: 10px;

    > h3 {
      font-size: 21px;
      font-family: 'EB Garamond';
    }

    > p {
      font-size: 14px;
      color: #8A9099;
    }
  }

  > a {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }
}


@media (min-width: 975px) {
  .--citc-shows-results {
    > .results {
      grid-gap: 24px;
      grid-template-columns: repeat(auto-fill, 220px);
    }

    > .no-result {
      gap: 16px;

      > h3 {
        font-size: 24px;
        font-family: 'EB Garamond';
      }

      > p {
        font-size: 16px;
        color: #8A9099;
      }
    }
  }
}


@media (min-width: 1080px) {
  .--citc-shows-results {
    > .results {
      grid-gap: 16px;
    }
  }
}
