.--citc-home-actors {
  text-align: center;
  background-color: #F2F4F6;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-radius: 8px;
  margin-right: 16px;
  margin-left: 16px;

  > .content {
    > h1 {
      text-align: center;
      font-family: 'EB Garamond', serif;
      font-size: 32px;
      margin-top: 16px;
      line-height: 36px;
      margin-bottom: 20px;
    }

    > p {
      display: none;
    }

    > a {
      display: none;
    }
  }

  > a {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  > .logos {
    margin: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    > img {
      margin-left: 8px;
      margin-right: 8px;
      width: 30%;
      height: auto;

      &.theatre-de-paris {
        max-width: 100px;
      }

      &.scala-paris {
        max-width: 120px;
      }

      &.theatre-montparnasse {
        max-width: 150px;
        display: none;
      }

      &.theatre-antoine {
        max-width: 120px;
        display: none;
      }

      &.theatre-national-strasbourg {
        max-width: 220px;
        display: none;
      }

      &.theatre-des-salinieres {
        max-width: 100px;
      }

      &.grand-palais {
        max-width: 70px;
        display: none;
      }

      &.musee-du-quai-branly {
        max-width: 150px;
        display: none;
      }

      &.paris-musees {
        max-width: 55px;
      }

      &.centre-des-monuments-nationaux {
        max-width: 150px;
        display: none;
      }

      &.mucem {
        max-width: 75px;
      }

      &.cite-du-vin {
        max-width: 90px;
      }

      &.opera-national-de-lyon {
        max-width: 70px;
      }

      &.orchestre-national-idf {
        max-width: 100px;
      }

      &.caramba {
        max-width: 90px;
        display: none;
      }
    }
  }

  @media (min-width: 700px) {
    > .logos {
      > img {
        &.theatre-antoine {
          display: block;
        }

        &.grand-palais {
          display: block;
        }
      }
    }
  }

  @media (min-width: 900px) {
    > .logos {
      > img {
        &.theatre-montparnasse {
          display: block;
        }

        &.musee-du-quai-branly {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1080px) {
    text-align: start;
    display: flex;
    background-color: transparent;
    padding-bottom: 0;

    > .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30%;
      margin-top: 8px;

      > h1 {
        text-align: start;
        width: fit-content;
      }

      > p {
        display: block;
        font-size: 16px;
        margin-bottom: 8px;
        width: auto;
      }

      > a {
        display: block;
      }
    }

    > .logos {
      display: flex;
      flex-wrap: wrap;
      width: 70%;

      > img {
        &.centre-des-monuments-nationaux {
          display: none;
        }
  
        &.mucem {
          display: block;
        }
  
        &.cite-du-vin {
          display: none;
        }
  
        &.opera-national-de-lyon {
          display: block;
        }
  
        &.orchestre-national-idf {
          display: none;
        }
  
        &.caramba {
          display: none;
        }
      }
    }

    > a {
      display: none;
    }
  }

  @media (min-width: 1252px) {

    > .logos {
      > img {
        &.centre-des-monuments-nationaux {
          display: block;
        }
  
        &.mucem {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1283px) {
    > .logos {
      > img {
        &.cite-du-vin {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1413px) {
    > .logos {
      > img {
        &.opera-national-de-lyon {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1425px) {
    > .logos {
      > img {
        &.orchestre-national-idf {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    > .content {
      h1 {
        line-height: 50px;
        font-size: 48px;
      }
    }
  }
}
