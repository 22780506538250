.--citc-slider {
  user-select: none;

  > .carousel-root {
    > .carousel {
      > .control-dots {
        > .dot {
          background-color: rgba(255, 255, 255, .6);
          border-radius: 27px;
          transition-property: width;
          transition-duration: 0.3s;

          &:only-child {
            display: none;
          }
        }

        > .selected {
          width: 50px;
          height: 8px;
          border-radius: 27px;
        }
      }

      > .slider-wrapper {
        border-radius: 8px;
        margin-top: 16px;
        width: 100%;
        height: auto;
        cursor: pointer;
      }
    }
  }
}