.news-letter-container {
  display: flex;
  flex-direction: column;
  background-color: #F2F4F7;
  text-align: left;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  padding: 16px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  width: 90%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  margin-top: 32px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, .1);

  >.news-letter-content {
    max-width: 550px;

    >h2 {
      font-size: 26px;
      font-weight: 500;
      font-family: 'EB Garamond', serif;
      margin-bottom: 8px;
    }

    >p {
      margin-bottom: 8px;
    }

    >form {
      display: flex;
      align-items: center;

      .--citc-custom-button {
        .text {
          display: none;
        }
      }

      >fieldset {
        position: relative;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid rgba(138, 144, 153, 0.2);
        border-radius: 4px;
        margin-right: 16px;
        transition: 1s;
        height: 45px;
        width: 100%;

        input {
          border: none;
          width: 100%;
          padding: 0 16px;
          overflow-x: hidden;
          text-overflow: ellipsis;

          &:focus {
            box-shadow: none !important;
          }
        }

        span {
          color: #0460F0;
          margin-left: 16px;

          &::after {
            font-family: culturinthecity;
            content: '\e979';
          }
        }
      }
    }
  }

  @media (min-width: 580px) {
    >.news-letter-content {
      >form {
        >fieldset {
          width: calc(100% - 164px);
        }

        .--citc-custom-button {
          .text {
            display: block;
          }

          .icon {
            display: none;
          }
        }
      }
    }
  }

  @media (min-width: 780px) {
    >.news-letter-content {
      >h2 {
        //font-size: 32px;
      }
    }

    >form {
      >fieldset {
        width: 400px;
      }
    }
  }

  @media (min-width: 1400px) {
    >.news-letter-content {
      >h2 {
        //font-size: 48px;
      }
    }
  }
}
