.offers-enterprise-page {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .info {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: 16px;
    margin-right: 16px;

    h2 {
      font-weight: 600;
      font-size: clamp(18px, 2vw, 24px);
    }

    p {
      font-size: clamp(14px, 2vw, 18px);
    }
  }
}

.offers-enterprise-banner {
  position: relative;
  width: auto;
  margin-left: 16px;
  margin-right: 16px;
  padding: 20px;
  height: auto;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 32px;
  color: #FFF;
  font-family: "EB Garamond", serif;
  text-align: center;
  background-color: #C43A3A;
  justify-content: center;

  p {
    margin-bottom: 15px;
  }

  .special-font {
    font-size: 60px;
    color: rgba(white, 0.6);
    rotate: -45;
    position: absolute;
    bottom: 2px;
    left: 15px;
    transform: rotate(-30deg);
  }

  .offers-enterprise-banner-gold {
    flex-wrap: wrap;
    color: #F3BC2C;
    text-shadow: -0.5px -0.5px 0.5px white, 0.5px 0.5px 1px rgba(0, 0, 0, 0.15);
  }
}

.offers-enterprise-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.offers-enterprise-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding-left: 15px;
  padding-right: 15px;

  h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }

  p {
    text-align: center;
    font-size: 14px;
    width: 290px;
  }

  img {
    width: 90px;
    margin-bottom: 20px;
  }
}

.offers-enterprise-info-line {
  width: 213px;
  border-bottom: solid 2px #CC942A;
  margin-top: 8px;
  margin-bottom: 8px;
}

.offers-enterprise-info-bolded-text {
  font-weight: 600;
}

.offers-enterprise-examples {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    width: 400px;
    font-family: "EB Garamond", serif;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
  }

}

.offers-enterprise-examples-end {
  width: 400px;
  font-family: "EB Garamond", serif;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
}

.offers-enterprise-examples-products {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.offers-enterprise-examples-product {
  width: 300px;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  img {
    max-width: 200px;
  }

  img:nth-child(1) {
    margin-bottom: auto;
    margin-top: auto;
  }

}

.offers-enterprise-examples-tag-container {
  display: flex;
  margin-top: 30px;
  align-items: center;
  width: 400px;

  span {
    color: #0A4E9B;
  }
}

.offers-enterprise-examples-occasion-desc {
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}

.offers-enterprise-examples-tag-occasion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #0A4E9B;
  font-weight: 600;

  p {
    width: 80px;
    text-align: center;
  }

  img {
    width: 36px;
  }
}


@media (min-width: 478px) {
  .offers-enterprise-banner {
    margin-top: 32px;
    font-size: 48px;
  }

}

@media (min-width: 640px) {
  .offers-enterprise-examples-occasion-desc {
    font-size: 18px;
    margin-left: 20px;

    span {
      font-size: 18px;
    }
  }

  .offers-enterprise-examples-products {
    display: grid;
    grid-template-columns: repeat(2, 300px);
    grid-template-rows: repeat(2, 300px);
    grid-gap: 10px;
    grid-auto-flow: row;
    justify-content: center;
  }

  .offers-enterprise-examples-end {
    width: auto;
  }

  .offers-enterprise-examples h2 {
    width: auto;
  }

  .offers-enterprise-examples-tag-occasion img {
    width: 66px;

  }

  .offers-enterprise-examples-tag-container {
    width: 600px;
    justify-content: space-between;
  }

}

@media (min-width: 748px) {
  .offers-enterprise-infos {
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

}

@media (min-width: 1300px) {
  .offers-enterprise-examples-occasions-container {
    display: flex;
    height: 870px;
  }

  .offers-enterprise-examples {
    justify-content: space-between;

    h2 {
      font-size: 42px;
    }
  }

  .offers-enterprise-col-separator {
    border-left: solid 2px #0A4E9B;
    height: 770px;
    margin-top: auto;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;

  }
}
