.--citc-about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  > h1 {
    font-family: 'EB Garamond';
    color: #3549FF;
    font-size: clamp(25px, 6vw, 48px);
    font-weight: 800;
    margin-top: 24px;
  }

  > img {
    width: 100%;
    max-width: 1100px;
    height: auto;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
    border-radius: 11px;

  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 72px;
    max-width: 1200px;

    > p {
      font-size: clamp(14px, 2.5vw, 26px);

      > span {
        color: #3549FF;
        font-weight: 600;
      }
    }
  }

}
