.home-page-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 1440px;
  margin: auto;

}

.home-page-banner {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  width: auto;
  overflow: hidden;

  .home-page-image-resized {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center bottom;
    border-radius: 8px 8px 0 0;
    transition: 1s;
    display: none;
  }

  h1,
  h2 {
    text-align: center;
    font-family: 'Inter', sans-serif;
    background: linear-gradient(89.99deg, rgba(0, 0, 0, 0.15) 0.59%, rgba(0, 0, 0, 0) 49.25%, rgba(0, 0, 0, 0.15) 100%), #FDD296;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 800;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 40px;
  }
}

.home-page-banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  background-color: #7c1c1c;
  border-radius: 8px;
  transition: 2s;
}

.home-page-avis-verifies {
  margin-top: 8px;
  margin-bottom: 8px;

  .avis-verifies-logo {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .avis-verifies-frame {
    display: none;

    &:focus {
      outline: none;
    }
  }
}

@media (min-width: 480px) {

  .home-page-banner {

    .home-page-banner-text {
      height: 300px;
    }

    .home-page-image-resized {
      height: 300px;
      transition: 2s;
    }

    h1 {
      font-size: 32px;
    }

    h2 {
      font-size: 24px;
    }

    p {
      margin-top: 10px;
      font-size: 20px;
    }
  }
}

@media (min-width: 720px) {

  .home-page-banner {
    display: flex;

    .home-page-banner-text {
      height: 350px;
      width: 50%;
      border-radius: 0 8px 8px 0;
    }

    .home-page-image-resized {
      display: block;
      height: 350px;
      width: 50%;
      border-radius: 8px 0 0 8px;
      transition: 2s;
    }

    h1 {
      font-size: 24px;
    }
  
    h2 {
      font-size: 20px;
    }

    p {
      margin-top: 20px;
      font-size: 16px;
    }
  }

  .home-page-avis-verifies {

    .avis-verifies-logo {
      display: none;
    }

    .avis-verifies-frame {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      height: 100%;
      display: block;
    }
  }
}

@media (min-width: 1040px) {

  .home-page-banner {

    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 28px;
    }

    p {
      font-size: 26px;
    }
  }
}

@media (min-width: 1440px) {

  .home-page-banner {

    .home-page-image-resized {
      height: 400px;
    }

    .home-page-banner-text {
      height: 400px;
      transition: 2s;
    }

    h1 {
      font-size: 40px;
    }

    h2 {
      font-size: 36px;
    }

    p {
      font-size: 32px;
    }
  }
}
