.top-products-container {
  margin-top: 32px;
  margin-bottom: 32px;

  h1 {
    font-family: 'EB Garamond', serif;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .top-products-head {
    margin-bottom: 8px;
    margin-left: 16px;
    margin-right: 16px;

    .top-products-nav {
      display: none;

      > a {
        display: none;
      }
    }
  }

  .top-products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    :nth-child(n+5):nth-child(-n+10) {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .top-products-cta {
    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 524px) {
  .top-products-container {
    margin-left: 16px;
    margin-right: 16px;

    .top-products-head {
      margin-left: 0;
      margin-right: 0;
    }

    .top-products-list {
      gap: 24px;
    }
  }
}

@media (min-width: 540px) {
  .top-products-container {
    .top-products-list {
      height: auto;
      margin-bottom: 24px;
    }

    .top-products-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .top-products-nav {
        display: flex;
        align-items: center;

        .top-products-rounded-icons {
          font-size: 18px;
          line-height: 18px;
          font-weight: 300;
          color: #0454F0;
          border: 1px solid #0454F0;
          padding: 5px;
          border-radius: 475%;
          width: 30px;
          display: inline-block;
          height: 30px;
          text-align: center;
          margin-left: 8px;
        }

        span:hover {
          cursor: pointer;
        }
      }
    }

    .top-products-list {
      flex-wrap: nowrap;
      justify-content: start;
      overflow-x: scroll;
      scroll-behavior: smooth;
      transition-duration: 5s;

      :nth-child(n+5):nth-child(-n+10) {
        display: flex;
      }

    }
  }
}

@media (min-width: 660px) {
  .top-products-container {
    .top-products-head {
      .top-products-nav {
        > a {
          display: flex;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .top-products-container {
    margin-top: 40px;
    margin-bottom: 40px;

    h1 {
      font-size: 48px;
    }

    .top-products-list {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
