.--citc-filters {
  height: calc(100vh - var(--header-height));
  height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  //background-color: #F2F4F7;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 0 !important;
  margin: 0.5rem 0;

  > .hd-filters {
    //overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color: rgba(0, 0, 0, .5) transparent;
    padding-right: 4px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    > div {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h1 {
        font-family: 'EB Garamond';
        font-size: 32px;
      }
    }

    > .section {
      border-top: 2px solid #8A9099;
      margin-top: 24px;
      padding-top: 16px;

      > .section--content {
        font-family: 'culturinthecity';
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        > .--citc-custom-input {
          margin-bottom: 0px !important;
        }
      }

      > .toggle-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        > h2 {
          font-size: 20px;
          font-weight: 600;
        }
      }

      &:first-child {
        border-top: 0;
        margin-top: 0;
      }

      &:last-child {
        display: none;
      }

      &.inactive {
        > .toggle-title {
          > span {
            transform: rotate(180deg);
          }
        }

        > div:last-child {
          display: none;
        }
      }

      > .--citc-custom-input {
        max-width: none;
      }

    }

    //overwrite CheckboxesListButton
    .list-items-checkbox {
      margin-bottom: 5px;
    }

    > .mobile-section {
      width: 100vw;
      margin: 0;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
      padding: 20px;
    }

    > .desktop-section {
      display: none;
    }
  }

}

@media (min-width: 975px) {
  .--citc-filters {
    height: auto;
    overflow: auto;

    > .hd-filters {
      > .header {
        > .--citc-custom-button {
          display: none;
        }
      }

      > .section {
        > .section--content {
          justify-content: space-around;
        }

        > &:last-child {
          display: block;
        }

      }

      > .mobile-section {
        display: none;
      }

      > .desktop-section {
        display: block;
      }
    }

  }
}