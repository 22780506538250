.--citc-header-tabs {
  display: flex;

  > a {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 10px;

    &:hover {
      color: #0454F0;
      transition: .5s;
    }

    &.active-path {
      color: #0454F0;
      font-weight: 600;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: var(--override-color, #0454F0);

      > span {
        &.icon {
          color: #0454F0;
        }
      }
    }

    &.bold {
      font-weight: 600;
    }

    &.red {
      color: #C43A3A;
      border-bottom-color: #C43A3A;

      &:hover {
        color: #E03030;
        border-bottom-color: #E03030;

        > span {
          &.icon {
            color: #E03030;
          }
        }
      }

      > span {
        &.icon {
          color: #C43A3A;
        }
      }
    }

    > span {
      &.icon {
        font-family: culturinthecity;
        font-size: 24px;
        color: #4b4e52;
        line-height: 14px;
        margin-top: 6px;

        &.dropdown-menu {
          font-size: 18px;
        }

        &:hover {
          cursor: pointer;
          color: #0454F0;
          transition: .5s;
        }
      }

      &.suffix {
        &.count {
          text-align: center;
          background-color: #0454F0;
          color: #F2F4F6;
          border-radius: 50%;
          font-weight: 600;
          font-size: 12px;
          padding-left: 2px;
          padding-right: 2px;
          min-width: 18px;
          min-height: 18px;
        }
      }
    }

    &.custom-color {
      color: var(--override-color, #ffffff);

      &:hover {
        color: color-mix(in srgb, currentColor 30%, var(--override-color, #ffffff));
      }
    }
  }

  &.desktop {
    > .menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 300;
      width: 100vw;
      height: auto;
      padding-top: 32px;
      padding-bottom: 32px;
      background-color: #F2F4F6;
      justify-content: space-around;
      gap: 24px;
      box-shadow: 0px 2px 3px rgba(37, 38, 40, .3);

      &.hidden {
        display: none;
      }

      > .content-tile {
        display: none;
      }
    }

    > a {
      > span {
        &.suffix {
          &.count {
            position: absolute;
            top: -2px;
            right: -6px;
          }
        }
      }
    }
  }

  &.mobile {
    flex-direction: column;
    gap: 16px;

    > a {
      font-weight: 600;
      width: fit-content;
      height: 32px;
      column-gap: 10px;
    }

    > .subMenu {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &.hidden {
        display: none;
      }

      > div {
        > h1 {
          font-family: 'EB Garamond';
          font-weight: 600;
          font-size: 26px;
          margin-bottom: 16px;
        }
      }

      > .content-tile {
        display: none;
      }
    }

    > p {
      font-weight: 600;
    }
  }
}

@media (min-width: 1080px) {
  .--citc-header-tabs {
    &.desktop {
      height: 100%;
      align-items: center;

      > a {
        user-select: none;
        height: 32px;

        &.active-path {
          margin-top: 2px;
        }
      }

      > .menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding: 20px 100px;

        > div {
          > h1 {
            font-family: 'EB Garamond';
            font-weight: 600;
            font-size: 26px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .--citc-header-tabs {
    &.desktop {
      > .menu {
        flex-wrap: nowrap;
        justify-content: space-evenly;
        padding: 20px 50px;

        > .content-tile {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1540px) {
    .--citc-header-tabs {
      &.desktop {
        > .menu {
          padding: 20px 200px;
        }
      }
    }
  }
}
