.--citc-custom-input {
  position: relative;
  min-width: 170px;
  max-width: 270px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5rem;

  > span {
    &.icon {
      font-family: culturinthecity;
      font-size: 20px;
      margin-left: 16px;
      color: #8A9099;
    }
  }

  > fieldset {
    margin: 4px 16px 3px;
    display: inline-block;

    > .label {
      font-weight: 600;
      user-select: none;
    }

    > .label + .input {
      margin-top: 4px;
    }

    > .input {
      position: relative;

      > input {
        color: rgba(255, 255, 255, 0.7);
        appearance: none;
        padding: 0;
        box-shadow: none;
        background-color: inherit;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        text-overflow: ellipsis;
        width: 100%;

        &:focus {
          outline: none;
          box-shadow: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.7);
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.4);
          opacity: 1;
        }
      }
    }
  }

  &.default-color {
    > fieldset {
      > .input {
        > input {
          color: rgba(0, 0, 0);
          margin: 0;

          &::placeholder {
            color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }

  &.default-input {
    margin-bottom: 24px;
    background-color: white;
    border: solid 1px #D2D5DA;
    width: auto;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 4px;

    > span {
      margin-left: 16px;
      color: #8A9099;
    }

    > fieldset {
      margin: 0;
      margin-left: 10px;

      > .label {
        font-weight: 600;
        user-select: none;
      }

      > .label + .input {
        margin-top: 4px;
      }

      > .input {
        position: relative;

        > input {
          color: rgba(0, 0, 0);
          appearance: none;
          padding: 0;
          box-shadow: none;
          background-color: inherit;
          border: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          text-overflow: ellipsis;
          width: 100%;

          &:focus {
            outline: none;
            box-shadow: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.7);
          }

          &::placeholder {
            color: rgba(0, 0, 0, 0.4);
            opacity: 1;
          }
        }
      }
    }
  }
}
