.DateRangePicker {
  > div {
    > .DateRangePickerInput {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      background-color: transparent;
      border: 0;

      > .DateInput {
        background-color: transparent;
        width: 80px;

        > input {
          width: 80px;
          font-size: 14px;
          color: black;
          border: 0;
          padding: 0;
          font-weight: 400;

          &::placeholder {
            font-weight: 400;
            color: #8A9099;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      > .DateRangePickerInput_calendarIcon {
        padding: 0;
        margin: 0 10px 0 0;

        > span {
          &.icon {
            font-family: culturinthecity;
            font-size: 16px;
            color: #8A9099;
          }
        }
      }

      > .DateRangePickerInput_arrow {
        margin-top: 2px;
        padding-left: 5px;
        padding-right: 5px;
        color: black !important;
        font-size: 14px;

        &:empty {
          margin-top: 0;
          padding-left: 0;
          padding-right: 0;
        }
      }

      > .DateRangePickerInput_clearDates {
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
        margin-right: 10px;
        background-color: white;
        border-radius: 150%;

        > .DateRangePickerInput_clearDates_svg {
          width: 9px;
          height: 9px;
          display: flex;
          margin-left: 3.5px;
          fill: #0454F0;
        }
      }

      > .DateRangePicker_picker {
        .DayPickerNavigation {
          width: 115px;
          margin-left: 200px;

          > .DayPickerNavigation_button {
            width: 32px;
            height: 32px;
            border-radius: 150%;
            display: flex;
            align-items: center;

            > .DayPickerNavigation_svg__horizontal {
              width: 16px;
              height: 16px;
              fill: black;
            }
          }
        }

        .CalendarMonth_caption {
          text-align: start;
        }


      }
    }
  }
}
