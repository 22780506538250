.--citc-custom-tag {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 24px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 12px;

  >p {
    position: relative;

    &.info:hover:after,
    .info:focus:after {
      content: attr(aria-label);
      width: 200px;
      position: absolute;
      left: -6px;
      top: 24px;
      color: #353636;
      background-color: #ffffff;
      border: 1px solid #20222220;
      padding: 4px;
      border-radius: 4px;
    }
  }

  &.e-product {
    color: #ffffff;
    background-color: #6e93c7;
  }

  &.physic {
    color: #fff;
    background-color: #d8ac6e;
  }
}
