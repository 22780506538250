.product-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: fit-content;
  height: 330px;

  .product-image {
    width: 164px;
    height: 181px;
    //padding: 20px;

    //background-color: #F2F4F7;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
    }

    img {
      width: auto;
      height: auto;
      max-height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .product-name {
    width: 164px;
    height: 63px;

    font-weight: 600;
    font-size: 16px;

    display: flex;
    align-items: start;

    color: #202222;

    &:hover {
      cursor: pointer;
    }
  }

  .product-price-tags {
    width: auto;
    height: auto;

    gap: 10px;

    font-weight: 400;
    font-size: 14px;

    display: flex;
    flex-direction: column;

    color: #3A3E44;

    &:hover {
      cursor: pointer;
    }

    > p {
      width: auto;
      color: #0454F0;
      font-weight: 600;
      margin-right: auto;
      margin-left: auto;
    }

    > .tags {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }
}

@media (min-width: 980px) {
  .product-container {
    height: 530px;

    .product-image {
      width: 293px;
      height: 360px;
    }

    .product-name {
      width: auto;
      font-size: 20px;
      width: 293px;
    }

    .product-price-tags {
      font-size: 20px;

      > .tags {
        justify-content: start;
      }

      > p {
        margin: 0;
      }
    }
  }
}
