.--citc-best-seller {

  >.products {
    display: flex;
    gap: 24px;

    >.tile {
      display: flex;
      flex-direction: column;
      gap: 16px;

      width: fit-content;
      height: 330px;

      >img {
        width: 160px;
        height: 196px;
        margin-left: auto;
        margin-right: auto;
      }

      >p {
        font-weight: 600;
        font-size: 16px;
      }

      >.name {
        width: 160px;
        height: 63px;

        display: flex;
        align-items: start;

        color: #202222;

      }
      
      >.price {
        color: #0454F0;
        font-weight: 600;
        margin-right: auto;
        margin-left: auto;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
