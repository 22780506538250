.theme-cards-container {
  width: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  user-select: none;
  margin-left: 16px;
  margin-right: 16px;

  .theme-cards-list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.theme-cards-title {
  font-family: 'EB Garamond', serif;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 8px;
}

.theme-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 96px;
  border-radius: 8px;

  .theme-card-title {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    overflow: hidden;
    padding: 16px;

    h2 {
      font-size: 26px;
      font-weight: 500;
      font-family: 'EB Garamond', serif;
    }

    .theme-card-link {
      display: none;
    }
  }

  .theme-card-image {
    overflow: hidden;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s;

    .theme-card-image-resized {
      width: 390px;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 8px 0 0 8px;
    }
  }
}

.theme-card-text {
  display: none;
}

.theme-card-red {
  background-color: #BC3F3F;
}

.theme-card-green {
  background-color: #629146;
}

.theme-card-blue {
  background-color: #185B99;
}

.theme-card-yellow {
  background-color: #CC962B;
}

@media (min-width: 590px) {
  .theme-cards-container {
    .theme-cards-list {
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: row;
      row-gap: 16px;
      column-gap: 32px;
    }
  }

  .theme-card-container {
    flex-direction: column;
    width: 230px;
    height: 320px;

    .theme-card-image {
      width: auto;

      .theme-card-image-resized {
        border-radius: 8px 8px 0 0;
      }
    }

    .theme-card-title {
      width: 100%;
      height: 240px;
    }
  }
}

@media (min-width: 800px) {

  .theme-cards-title {
    text-align: center;
  }

  .theme-cards-list {
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
  }

  .theme-card-container {
    width: 293px;
    height: 399px;

    .theme-card-title {
      font-size: 26px;
      padding: 0;
      display: block;
      text-align: start;
      transition: 1s;

      h2 {
        margin-top: 32px;
        margin-left: 16px;
      }
    }
  }

  .theme-card-text {
    display: block;
    margin-right: 16px;
    margin-left: 16px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .theme-card-container:hover {

    cursor: pointer;

    .theme-card-image {
      filter: contrast(125%);
      transition: 1s;
    }

    .theme-card-title {
      height: 450px;
      transition: 1s;
    }

    .theme-card-link {
      margin-left: 16px;
      margin-top: 26px;
      font-size: 16px;
      font-weight: 600;
      width: fit-content;
      display: block;
      padding-bottom: 10px;
      border-bottom: solid 2px white;
      animation: fadeIn 2s;

      &:hover {
        text-shadow: 0px 0px 2px rgb(255, 255, 255);
        transition: 1s;

      }

      .special-font {
        font-size: 16px;
        margin-left: 6px;
      }
    }
  }
}

@media (min-width: 1380px) {
  .theme-cards-list {
    max-width: none;
    flex-wrap: nowrap;
  }

  .theme-card-container {
    width: 288px;
    height: 399px;

    .theme-card-title {
      height: 260px;
      font-size: 30px;
    }
  }
}

@media (min-width: 1440px) {
  .theme-cards-title {
    font-size: 48px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}