.--citc-custom-button {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  white-space: pre-wrap;
  height: 45px;
  width: fit-content;
  cursor: pointer;
  padding: 4px 8px;
  box-shadow: none;
  box-sizing: border-box;
  user-select: none;

  > span {
    padding: 0 4px;

    &.left-icon,
    &.right-icon {
      font-family: culturinthecity;
    }
  }

  &.fit {
    width: 100%;
    justify-content: center;
  }

  &.primary,
  &.danger,
  &.black {
    color: #ffffff;

    &:hover {
      color: #e6e6e6;
    }
  }

  &.custom-color {
    color: var(--override-color, #ffffff);

    &:hover {
      color: var(--override-color, #e6e6e6);
    }
  }

  &.secondary {
    color: #0454F0;

    &:hover {
      color: #0460F0;
    }
  }

  &.white {
    color: #202222;

    &:hover {
      color: #383838;
    }
  }

  &.red {
    color: #AF1414;

    &:hover {
      color: #C43A3A;
    }
  }

  &.pink {
    color: #A92351;

    &:hover {
      color: #bd3363;
    }
  }

  &.sm {
    height: 24px;
    padding: 4px 8px;
  }

  &.m {
    height: 35px;
  }

  &.xl {
    font-size: 18px;
    padding: 4px 16px;
  }

  &.custom-calendar {
    height: 37px;
    width: 48%;
    font-size: 13px;

    > span {
      margin: 0 auto;
    }
  }

  &.broke-corner {
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 200% 75px;
    border-radius: 4px;

    &.sm {
      background-size: 200% 32px;
      padding: 4px 8px;
    }

    &.m {
      background-size: 200% 50px;
      padding: 4px 10px;
    }

    &.primary {
      background-image: linear-gradient(45deg, #0454F0 50%, #b9454500 0);

      &:hover {
        background-image: linear-gradient(45deg, #0460F0 50%, #b9454500 0);
      }
    }

    &.custom-color {
      background-image: linear-gradient(45deg, var(--override-background-color, #0454F0) 50%, #b9454500 0);

      &:hover {
        background-image: linear-gradient(45deg, var(--override-background-color, #0460F0) 50%, #b9454500 0);
      }
    }

    &.secondary,
    &.white,
    &.red,
    &.pink {
      background-image: linear-gradient(45deg, #ffffff 50%, #b9454500 0);

      &:hover {
        background-image: linear-gradient(45deg, #ececec 50%, #b9454500 0);
      }
    }

    &.black {
      background-image: linear-gradient(45deg, #202222 50%, #b9454500 0);

      &:hover {
        background-image: linear-gradient(45deg, #383838 50%, #b9454500 0);
      }
    }


    &.danger {
      background-image: linear-gradient(45deg, #c91919 50%, #b9454500 0);

      &:hover {
        background-image: linear-gradient(45deg, #C43A3A 50%, #b9454500 0);
      }
    }
  }

  &.underline {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    text-decoration: none;
    padding: 0;
    height: 32px;

    > span {
      padding: 0;

      &.left-icon,
      &.right-icon {
        padding: 0 4px;
      }
    }

    &.sm {
      height: 24px;
    }

    &.primary {
      color: #0454F0;
      border-bottom-color: #0454F0;

      &:hover {
        color: #0460F0;
        border-bottom-color: #0460F0;
      }
    }

    &.custom-color {
      color: var(--override-background-color, #0454F0);
      border-bottom-color: var(--override-background-color, #0454F0);

      &:hover {
        color: var(--override-background-color, #0460F0);
        border-bottom-color: var(--override-background-color, #0460F0);
      }
    }

    &.secondary,
    &.white {
      border-bottom-color: #ffffff;

      &:hover {
        color: #e6e6e6;
        border-bottom-color: #e6e6e6;
      }
    }

    &.black {
      color: #202222;
      border-bottom-color: #202222;

      &:hover {
        color: #383838;
        border-bottom-color: #383838;
      }
    }

    &.red {
      border-bottom: 3px solid #AF1414;

      &:hover {
        border-bottom-color: #C43A3A;
      }
    }

    &.pink {
      border-bottom: 3px solid #A92351;

      &:hover {
        border-bottom-color: #bd3363;
      }
    }
  }

  &.no-background {
    border: 1px solid;
    border-radius: 4px;
  }

  &.default {
    border-radius: 4px;

    &.primary {
      background-color: #0454F0;

      &:hover {
        background-color: #0460F0;
      }
    }

    &.custom-color {
      background-color: var(--override-background-color, #0454F0);

      &:hover {
        background-color: var(--override-background-color, #0460F0);
      }
    }

    &.secondary,
    &.white {
      background-color: #ffffff;

      &:hover {
        background-color: #e6e6e6;
      }
    }

    &.black {
      background-color: #202222;

      &:hover {
        background-color: #383838;
      }
    }

    &.red {
      color: #ffffff;
      background-color: #AF1414;

      &:hover {
        color: #e6e6e6;
        background-color: #C43A3A;
      }
    }

    &.pink {
      color: #ffffff;
      background-color: #A92351;

      &:hover {
        color: #e6e6e6;
        background-color: #bd3363;
      }
    }
  }

  &:hover {
    box-shadow: none;
  }

  &.black-shadow {
    box-shadow: -2px 3px 3px rgba(black, 0.25);

    &:hover {
      box-shadow: none;
      transition: .3s;
    }
  }
}
