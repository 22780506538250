.--citc-where-find-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  > h1 {
    font-family: 'EB Garamond';
    color: #3549FF;
    font-size: clamp(25px, 6vw, 48px);
    font-weight: 800;
    margin-top: 24px;
  }

  > .section {
    display: flex;
    flex-direction: column;
    gap: 72px;
    max-width: 1200px;

    > p {
      font-size: clamp(14px, 2.5vw, 26px);

      > span {
        color: #3549FF;
        font-weight: 600;
      }
    }
  }

  > .map-container {
    height: 400px;
    width: 100%;
  }

  > .brands {
    > h1 {
      font-size: clamp(18px, 5vw, 36px);
      font-weight: 700;
      margin-bottom: 50px;
    }

    > div {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-gap: 10px;

      > img {
        width: 100%;
        height: auto;
        padding: 10px 16px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}
