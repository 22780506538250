.--citc-draggable-slider-tab {
  position: relative;
  overflow-x: hidden;
  max-width: 100%;

  > ul {
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
    touch-action: none;
    justify-content: start;
    margin-top: 32px;
    font-family: 'EB Garamond';
    font-size: 18px;

    &.dragging {
      scroll-behavior: auto;
      cursor: grab;
    }

    > li {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #696969;
      font-size: 18px;
      padding-bottom: 19px;
      min-width: 170px;
      width: 100%;
      user-select: none;

      > &.active {
        border-bottom: 4px solid #0454F0;
        color: #0454F0;
        padding-bottom: 16px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  > .icon {
    position: absolute;
    bottom: 0;
    display: none;
    width: 30px;
    display: flex;
    align-items: center;
    height: 80%;
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }

    &.icon-left {
      display: none;
      background: linear-gradient(90deg, #fff 70%, transparent);
      left: 0;

      &::before {
        margin-left: 5%;
      }
    }

    &.icon-right {
      display: none;
      background: linear-gradient(-90deg, #fff 70%, transparent);
      right: 0;
      justify-content: end;

      &::before {
        margin-right: 5%;
      }
    }
  }
}

@media (min-width: 975px) {
  .--citc-draggable-slider-tab {
    > ul {
      > li {
        min-width: 190px;
        font-size: 20px;
      }
    }
  }
}
