@keyframes loading-ellipsis-1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loading-ellipsis-2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(16px, 0);
  }
}

@keyframes loading-ellipsis-3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.--citc-custom-pane {
  position: absolute;
  width: 100%;
  min-width: 180px;
  max-width: var(--max-width);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5rem;
  z-index: 1;

  top: var(--override-offset-top, var(--offset-top));
  bottom: var(--override-offset-bottom, var(--offset-bottom));
  left: var(--override-offset-left, var(--offset-left));
  right: var(--override-offset-right, var(--offset-right));

  > .pane {
    padding: 8px 4px 8px 0;
    margin: 0;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 4px;
    box-shadow: 2px 2px 2px rgba(59, 59, 59, 0.021);
    background-color: #FFF;
    color: #000;

    &.hide {
      display: none;
    }

    &.info {
      color: #084298;
      background-color: #cfe2ff;
      border-color: #b6d4fe;
    }

    &.danger {
      color: #842029;
      background-color: #f8d7da;
      border-color: #f5c2c7;
    }

    &.success {
      color: #0f5132;
      background-color: #d1e7dd;
      border-color: #badbcc;
    }

    &.warning {
      color: #664d03;
      background-color: #fff3cd;
      border-color: #ffecb5;
    }

    > .view-all {
      display: block;
      color: black;
      border-top: 1px solid rgb(226, 226, 226);
      margin-top: 8px;
      margin-right: -4px;
      padding: 8px 8px 0;
      text-align: center;
      user-select: none;
      font-weight: 600;
    }

    > .search {
      color: black;
      border-bottom: 1px solid rgb(226, 226, 226);
      margin-bottom: 8px;
      margin-right: -4px;
      padding: 0 8px;

      > .icon + input {
        padding: 0 16px 8px 32px;
      }

      > input + .icon {
        &::after {
          right: 16px;
          left: inherit;
        }
      }

      > input {
        appearance: none;
        padding: 0 32px 8px 16px;
        border-radius: 4px 4px 0 0;
        border: 0;
        box-shadow: none;
        background-color: white;
        width: 100%;
        font-size: inherit;
        line-height: inherit;

        &:focus {
          outline: none;
          border-color: rgb(226, 226, 226);
        }

        &::-ms-clear,
        &::-ms-reveal {
          display: none;
          width: 0;
          height: 0;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;
        }
      }

      > .icon {
        cursor: default;

        &::after {
          position: absolute;
          font-family: culturinthecity;
          font-size: 16px;
          line-height: 16px;
          content: '\ead6';
          top: 14px;
          left: 18px;
          font-weight: 600;
        }
      }
    }

    > .search + .options {
      border-radius: 0 0 4px 4px;
      border-top: none;
      max-height: 176px;
    }

    > .options,
    > .errors {
      margin: 0;
      padding: 0 4px 0 8px;
      list-style: none;
      cursor: default;
      overflow-y: scroll;
      max-height: 176px;
      user-select: none;
      scrollbar-color: rgba(0, 0, 0, .5) transparent;

      _::-webkit-full-page-media,
      & {
        max-height: 187px;
      }

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }

      > .option,
      > .message,
      > .no-results {
        padding: 4px 8px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    > .options {
      > .loading {
        position: relative;
        text-align: center;

        .ellipsis {
          display: inline-block;
          position: relative;
          width: 40px;
          height: 8px;
          text-align: left;

          span {
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgb(4, 83, 240);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);

            &:nth-child(1) {
              animation: loading-ellipsis-1 0.6s infinite;
            }

            &:nth-child(2) {
              animation: loading-ellipsis-2 0.6s infinite;
            }

            &:nth-child(3) {
              left: 16px;
              animation: loading-ellipsis-2 0.6s infinite;
            }

            &:nth-child(4) {
              left: 32px;
              animation: loading-ellipsis-3 0.6s infinite;
            }
          }
        }
      }

      &:has(.option):has(.sub-label) {
        max-height: 297px;
      }

      > .option {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        cursor: pointer;

        &:hover {
          border-radius: 4px;
          background-color: rgba(4, 83, 240, 0.1);
        }

        > .label {
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > .sub-label {
          font-size: 12px;
          color: #8A9099;
        }

        > span {
          vertical-align: middle;

          &.checkbox {
            font-family: culturinthecity;
            font-size: 22px;
            line-height: 22px;
            margin-right: 8px;
            cursor: pointer;

            &.unchecked {
              &::before {
                content: '\ecb4';
                color: #8A9099;
              }
            }

            &.checked {
              &::before {
                content: '\ecb5';
                color: rgb(4, 83, 240);
              }
            }
          }
        }
      }
    }
  }
}
