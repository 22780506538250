.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: #ffffff;
  color: #000000;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #b8b8b8;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  z-index: 100;
}

.tooltip.active .tooltip-text {
  opacity: 1;
  visibility: visible;
}