.--citc-custom-checkboxes-list {
  > .list-container-checkbox {
    display: block;
    margin-bottom: 16px;

    div {
      margin-bottom: 14px;

      input {
        margin-right: 10px;
      }
    }

    > .list-items-checkbox {
      padding-left: 10px;

      &, label, input {
        cursor: pointer;
      }

      label {
        display: flex;
        align-items: center;
        width: 200px;
      }

      input {
        display: none;
        margin-right: 10px;
      }

      span {
        margin-right: 16px;
        color: #8A9099;
      }

      input + label > span::before {
        content: '\ecb4';
      }

      input:checked + label > span::before {
        content: '\ecb5';
        color: rgb(4, 83, 240);
      }

      input:checked + label {
        font-weight: 500;
      }

      &:hover {
        border-radius: 2px;
        background-color: rgb(4, 83, 240, .1);
      }

      &.radio {
        input + label > span::before {
          content: '\ecb1';
        }

        input:checked + label > span::before {
          content: '\ecb3';
          color: rgb(4, 83, 240);
        }
      }
    }
  }
}
