.--citc-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 48px;
  background-color: #fff;
  padding: 0;
  position: relative;
  color: #3A3E44;
  box-shadow: 0px 1px 6px #3A3E4440;
  -webkit-user-drag: none;
  user-select: none;

  > .burger-menu {
    width: 20%;

    > .icon {
      font-family: culturinthecity;
      font-size: 32px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  > .left,
  > .right {
    display: flex;
    align-items: center;

    > ul {
      display: flex;
      align-items: center;

      > li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }

      > .flex-column-tab {
        display: flex;
        flex-direction: column;
        align-items: center;

        > a {
          display: none;
          font-size: 14px;
          font-weight: 600;
        }

        &:hover {
          cursor: pointer;
          color: #0454F0;
        }
      }
    }
  }

  > .left {
    gap: 32px;
    height: 100%;

    > img {
      height: 38px;
      width: auto;

      &:hover {
        cursor: pointer;
      }
    }

    > ul {
      display: none;
      height: 100%;
      overflow: hidden;

      > li {
        height: 100%;
      }
    }
  }

  > .right {
    justify-content: flex-end;
    width: 20%;

    > ul {
      > li {
        &:first-child {
          display: none;
        }

        &:last-child {
          margin-right: 0;
        }

        > .--citc-custom-button {
          display: none;
        }

        > .--citc-header-tabs {
          > a {
            &.active-path {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  > .mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #F2F4F6;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    transform: translateX(0%);
    transition: .3s linear;
    overflow: scroll;
    padding-right: 16px;
    padding-left: 16px;


    &.hidden {
      transform: translateX(-100%);
      transition: .3s linear;
    }

    > .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 5px;

      > h2 {
        font-family: 'EB Garamond';
        font-size: 32px;
        font-weight: 500;
      }
    }

    > ul {
      > li {
        border-bottom: 1px solid #D2D5DA;
        padding: 20px 0;

        &:last-child {
          border-bottom: none;
        }

        > a {
          height: 75px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 25px;
          padding-top: 25px;
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .--citc-header {
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;

    > .left,
    > .right {
      > ul {
        > li {
          margin-right: 16px;
        }
      }
    }
  }
}

@media (min-width: 500px) {
  .--citc-header{
    > .right {
      > ul {
        > .flex-column-tab {
          > a {
            display: block;
          }
        }
      }
    }
  }
}

@media (min-width: 1080px) {
  .--citc-header {
    height: 88px;

    > .burger-menu {
      display: none;
    }

    > .left {
      > ul {
        display: block;
      }
    }

    > .right {
      width: inherit;
      min-width: 318px;

      > ul {
        > li {
          &:first-child {
            display: inline-block;
          }

          > .--citc-custom-button {
            display: flex;
          }
        }
      }
    }
  }
}
