.reinsurance-desktop-text {
  display: none;
}

.reinsurance-mobile-text {
  font-size: 12px;
  text-align: center;
}

.reinsurance-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 32px;
  margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0px 15px 6px rgba(37, 38, 40, 0.01), 0px 9px 5px rgba(37, 38, 40, 0.02), 0px 4px 4px rgba(37, 38, 40, 0.03), 0px 1px 2px rgba(37, 38, 40, 0.04), 0px 0px 0px rgba(37, 38, 40, 0.04);

  .special-font {
    color: #0454F0;
    margin-bottom: 2px;
  }

  .reinsurance-section {
    display: flex;
    flex-direction: column;
    width: 31%;
    margin-left: 8px;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 860px) {
  .reinsurance-section {
    .special-font {
      display: none;
    }

    p>span {
      color: #0454F0;
      font-weight: 600;
    }

    h2 {
      padding-bottom: 8px;
      width: fit-content;
      border-bottom: solid 2px #0454F0;
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
    }

    p {
      font-size: 12px;
    }

    .reinsurance-desktop-text {
      display: block;
      text-align: center;
    }

    .reinsurance-mobile-text {
      display: none;
    }
  }
}

@media (min-width: 1080px) {

  .reinsurance-section {

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }
  }
}
