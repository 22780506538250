.results-list-search {
    width: 90%;
    max-height: 160px;
    background-color: white;
    margin-top: 10px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    overflow-y: scroll;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.30);
}

.result-search {
    padding-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.result-search p{
    border-bottom: solid 1px rgba(128, 128, 128, 0.1);
}

.result-search p:hover{
    background-color: rgba(4, 83, 240, 0.1);
    cursor: pointer;
}

.results-list-title-info {
    font-weight: 500;
    margin-bottom: 8px;
}
