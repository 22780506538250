.--citc-custom-menu {

  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  >ul {
    >li {
      margin-bottom: 8px;

      >a {
        font-size: 16px;

        &.bold {
          font-weight: 600;
        }

        &:hover {
          color: #0454F0;
          transition: .5s;
        }

        &.red {
          color: #C43A3A;

          &:hover {
            color: #e03030;
          }
        }

        &.custom-color {
          color: var(--override-color, #ffffff);

          &:hover {
            color: color-mix(in srgb, currentColor 30%, var(--override-color, #ffffff));
          }
        }
      }

      .--citc-custom-button {
        .text {
          font-size: 16px;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
