.--citc-custom-single-select, .--citc-custom-multiple-select {
  position: relative;
  min-width: 170px;
  max-width: 270px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5rem;

  > .label {
    font-weight: 600;
    cursor: default;
    margin: 4px 16px 0;
    user-select: none;
  }

  > .select {
    position: relative;
    color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    margin: 4px 16px;
    user-select: none;
    height: 24px;

    &.close {
      > .arrow {
        &::after {
          content: '\ed7a';
        }
      }
    }

    &.open {
      > .arrow {
        &::after {
          content: '\ed7c';
        }
      }
    }

    > .values {
      display: inline-block;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 24px);

      > span {
        display: inline-block;
        line-height: 24px;

        &.value {
          color: rgba(255, 255, 255, 0.7);
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 4px;
          padding: 0 4px;
          margin-right: 4px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    > .arrow {
      display: inline-block;
      width: 24px;
      height: 24px;

      &::after {
        position: absolute;
        font-family: culturinthecity;
        font-size: 22px;
        line-height: 22px;
        font-weight: 600;
        content: '';
        right: 2px;
        top: 0;
      }
    }
  }
}