.--citc-how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  > iframe {
    width: 90%;
    height: 40vw;
    max-width: 920px;
    max-height: 425px;
  }

  > h1 {
    font-family: 'EB Garamond';
    color: #3549FF;
    font-size: clamp(25px, 6vw, 48px);
    font-weight: 800;
    margin-top: 24px;
  }

  > .section {
    display: flex;
    flex-direction: column;
    gap: 72px;
    max-width: 1200px;

    > p {
      font-size: clamp(14px, 2.5vw, 26px);

      > span {
        color: #3549FF;
        font-weight: 600;
      }
    }

    > div {
      > p {
        font-size: clamp(14px, 2.5vw, 26px);
      }

      > div {
        display: flex;
        align-items: baseline;

        > p,
        a {
          font-size: clamp(14px, 2.5vw, 26px);
        }
      }
    }
  }
}
