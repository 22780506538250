.--citc-loader {
  display: flex;
  justify-content: center;
  margin: 2rem;

  > &::after {
    content: "";
    width: 70px;
    height: 70px;
    border: 10px solid #dddddd;
    border-top-color: #0454F0;
    /* border-bottom-color: #60d460;  -- optional */
    border-radius: 50%;
    /* animation: spin 0.6s 0.1s linear infinite both; -- optional */
    animation: spin 0.8s 0.1s ease-in-out infinite both;
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
}