.search-bar {
  background-color: #0454F0;
  color: #ffffff;
  width: auto;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px 1px 2px rgb(37, 38, 40, .4);
  transition: height 0.5s linear;

  &.hide {
    height: 0;
  }

  .separator {
    width: 1px;
    height: 40px;
    background-color: rgb(255, 255, 255, 0.5);
  }

  .input-with-button {
    display: flex;
    align-items: end;

    .--citc-custom-button {
      margin: 4px 16px 4px 0;
    }
  }

  .desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-weight: 600;
    }

    .inputs {
      display: flex;
      align-items: center;
    }

    &.hide {
      display: none;
    }

    .--citc-custom-pane {
      width: calc(100% + 32px);
      --override-offset-top: 40px;
      --override-offset-left: -16px;
    }
  }

  .mobile {
    display: none;
    align-items: center;
    width: 100%;
    height: 100%;

    .--citc-custom-button {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    &.hide {
      display: none;
    }
  }
}

@media (max-width: 830px) {
  .search-bar {
    height: 56px;

    .mobile {
      display: flex;
    }

    .desktop {
      display: none;
    }
  }
}
