.event-tiles-container {
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;

  > .event-tiles-title {
    font-size: 32px;
    font-weight: 500;
    font-family: 'EB Garamond';
    margin-bottom: 20px;
  }

  .event-tiles-list {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-behavior: smooth;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .event-tiles-rounded-icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
    font-weight: 300;
    color: #000;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 8px;
    border-radius: 475%;
    width: 30px;
    display: inline-block;
    height: 30px;
    text-align: center;
  }

  .icon-arrow-left {
    left: 8px;
  }

  .icon-arrow-right {
    right: 8px;
  }

  span:hover {
    cursor: pointer;
  }

  > .--citc-custom-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}

@media (min-width: 1080px) {
  .event-tiles-container {
    > .event-tiles-title {
      font-size: 36px;
    }

    .event-tiles-rounded-icons {
      width: 50px;
      height: 50px;
      font-size: 35px;
    }
  }
}

@media (min-width: 1440px) {

  .event-tiles-container {
    background-color: transparent;

    > .event-tiles-title {
      font-size: 48px;
    }
  }

}
