.search-bar-title {
  display: none;
  font-weight: 600;
}

.input-area{
    display: flex;
    justify-content: center;
}

.search-bar-input{
    background-color: rgb(0, 0, 0, 0) !important;
    color: #ffffff;
    width: 87px;
    margin-left: 10px;
    padding: 0 !important;
    border: none;
}

.input-type-date{
  position: relative;
  border: none;
  padding: 0;
}

.special-font {
  font-family: culturinthecity;
  font-size: 20px;
}

@media (min-width: 830px) { 
  .search-bar-title {
    display: block;
    margin-left: 16px;
  }

  .search-bar-input{
    width: auto;
    margin-left: 16px;
  }

  .search-bar-icon{
    display: none;
  }

  .input-area{
    height: 50px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
