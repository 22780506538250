.--citc-banner {
  position: relative;
  //background-color: rgb(0, 173, 238);
  background-color: #BC3F3F;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #ffffff;
  height: 64px;

  &.with-link {
    height: 86px;
  }

  > .content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 32px);
    text-align: center;
    justify-content: center;
    align-items: center;

    > .long, > .short {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }

    > .long {
      display: none;
      -webkit-line-clamp: 1;
    }

    > .short {
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }

    > a {
      text-decoration: underline;
      user-select: none;
    }
  }

  @media (min-width: 768px) {
    height: 40px;

    &.with-link {
      height: 64px;
    }

    > .content {
      > .long {
        display: -webkit-box;
      }

      > .short {
        display: none;
      }
    }
  }
}
